class ErrorsCollector {
  constructor() {
    this.num_reports = 0;
  }

  report(message, source, lineno, colno, error) {
    console.error("******** error: ", message, source, lineno, colno);
    console.error("******** details: ", error);

    this.num_reports += 1;
    if (this.num_reports > 3) {
      console.debug("******** too many reports, not reporting anymore");
      return true;
    }

    const now = new Date();
    $.ajax({ method: "POST",
      url: `/api/js_errors`,
      contentType: "application/json",
      data: JSON.stringify({
          error: { message: message, source: source, lineno: lineno, colno: colno, stack: error.stack },
          reported_at: now.toISOString(),
          user_agent: navigator.userAgent,
          url: window.location.href,
        }),
      dataType: 'json',
      success: function(data, textStatus, xhr) {
        var json = data;
        console.log(`post error report success`);
       },
      error: function(data, textStatus, xhr) {
       var json = data.responseJSON;
       console.error(`err: ${json.error}, code: ${data.status}`);
      }
    });
  }
}

var error_collector = new ErrorsCollector();
window.onerror = function (message, source, lineno, colno, error) {
  error_collector.report(message, source, lineno, colno, error);
  return true;
};
