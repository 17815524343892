import VChartsLoader from './lib/vcharts_loader.js';
import { intersectionObserverLazyLoad } from './global';
import { show_layout_modal } from "./global";
import { vpsb_lazy_load_images } from '../vpsb/lazy_loader.js';

class CompareManagedVpsPage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
  }

  init() {
    this.vchartsLoader.init();
    this.setup_provider_checkboxes();
    this.setupLazyLoadCharts();
  }

  setupLazyLoadCharts() {
    intersectionObserverLazyLoad("lazy-chart", "300px", (container) => {
      this.load_simple_chart(`#${container.id}`);
    });
  }

  load_simple_chart(canvasId) {
    var canvas = $(canvasId);
    if (canvas.length == 0) {
      console.error(canvas_id + " is missing from yabs page");
      return;
    }

    var ctx = canvas.get(0).getContext("2d");
    var num_cores = canvas.data('num-cores');
    var metrics = canvas.data('metrics');
    var hoster_ids = this.checked_hoster_ids();
    $.ajax({
      type: 'GET',
      url: `/compare/managed_vps/chart_data/prices/${num_cores}/${metrics}`,
      data: { hoster_ids: hoster_ids },
      dataType: 'json',
      success: (data) => {
        console.log("success managed_vps price charts_data");
        this.vchartsLoader.simple_chart(ctx, 'bar', data);
      }
    })
  }

  setup_provider_checkboxes() {
    $("#provider-checkboxes div.hoster-item").on('click', (e) => {
      var box = e.currentTarget;
      if (!$(box).hasClass('checked') && $("#provider-checkboxes div.hoster-item.checked").length > 2) {
        show_layout_modal("You can only compare up to 3 providers at a time.");
        return;
      }

      $(box).toggleClass('checked');
      var hoster_id = $(box).data('hoster-id');
      var checked = $(box).hasClass('checked');
      console.debug("provider checkbox changed", hoster_id, checked);

      this.load_comparison();
    });
  }

  checked_hoster_ids() {
    return $("#provider-checkboxes div.hoster-item.checked").map((i, elt) => {
      return $(elt).data('hoster-id');
    }).get().join(',');
  }

  load_comparison() {
    var hoster_ids = this.checked_hoster_ids();
    $.ajax({
      type: 'GET',
      url: `/compare/managed_vps/comparison`,
      data: { hoster_ids: hoster_ids },
      success: (data) => {
        console.log("success managed_vps comparison");
        $("#comparison-container").html(data);
        this.setupLazyLoadCharts();
        vpsb_lazy_load_images();
      },
      error: (data, textStatus, xhr) => {
        console.error(`Failed to load comparison ${data.status}`);
      }
    })
  }
}

$(document).on('turbo:load', function() {
  if ($("body.compare_managed_vps").length == 0) {
    return;
  }
  console.log("page load in compare_managed_vps.js");

  var compare_mvps_page = new CompareManagedVpsPage();
  compare_mvps_page.init();
});
