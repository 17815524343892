
export function vpsb_deselect_all_checkboxes(button_id, form_id) {
  $(button_id).on('click', (event) => {
    $(form_id + ' input:checkbox:checked').each(function() {
      $(this).prop("checked", false);
    });
    return false;
  });
}

export function vpsb_insert_human_token(form_elt) {
  if ($(form_elt).length == 0) {
    return;
  }

  var rightNow = new Date();
  var res = rightNow.toISOString().slice(0,10).replace(/-/g,"");
  var token = res.vpsbHashCode();
  $(form_elt).each( (index, elt) => {
    $(elt).append('<input name="human_token" value="' + token + '" type="hidden">');
  });
}

String.prototype.vpsbHashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = (hash << 2) + chr;
  }
  return hash;
};

export function vpsbGetPagesInSession(cookies) {
  var numPages = cookies.get('numPagesInSession');
  if (typeof numPages !== 'undefined') {
    return numPages;
  } else {
    return 0;
  }
}

class ReloadWithTurbolinks {
  constructor() {
    this.scrollPosition = null;
  }

  adjustScroll() {
    if (this.scrollPosition) {
      console.log("Scrolling to recorded position for reload");
      window.scrollTo.apply(window, this.scrollPosition);
      this.scrollPosition = null;
    }
  }

  storeScrollPosition() {
    this.scrollPosition = [window.scrollX, window.scrollY];
  }

  reloadPage() {
    Turbo.visit(window.location.toString(), { action: 'replace' });
  }
}
var reloadWithTurbolinks = new ReloadWithTurbolinks();

function reloadPageWithTurbolinks() {
  reloadWithTurbolinks.storeScrollPosition();
  reloadWithTurbolinks.reloadPage();
}

export function show_layout_modal(message, redirect_to, reload) {
  $('#layout-modal-body').html(message);
  $('#layout-modal').on('shown.bs.modal', () => {
    $('#layout-modal-close').trigger('focus');
  });
  $('#layout-modal').modal({});

  if (typeof redirect_to !== 'undefined') {
    $('#layout-modal-close').on('click', () => {
      Turbo.visit(redirect_to, { action: 'replace' })
    });
  } else if (typeof reload !== 'undefined' && reload === true) {
    $('#layout-modal-close').on('click', () => {
      reloadPageWithTurbolinks();
    });
  }
}

export function show_multiple_choice_modal(body, yes_label, no_label, yes_action, no_action) {
  if (typeof body !== 'undefined') {
    $('#choice-modal #choice-modal-body').html(body);
  }
  if (typeof yes_label !== 'undefined') {
    $('#choice-modal #choice-modal-yes').html(yes_label);
  }
  if (typeof no_label !== 'undefined') {
    $('#choice-modal #choice-modal-no').html(no_label);
  }

  $('#choice-modal button#choice-modal-yes').off('click');
  $('#choice-modal button#choice-modal-no').off('click');

  $('#choice-modal').on('shown.bs.modal', () => {});
  $('#choice-modal button#choice-modal-yes').on('click', yes_action);
  $('#choice-modal button#choice-modal-no').on('click', no_action);

  $('#choice-modal').modal({});
}


export function showEphemeral(message) {
  $('.ephemeral-box').html(message);
  $('.ephemeral-box').removeClass('hidden');
  $('#ephemeral-box-container').addClass('ontop');
  setTimeout(function(){
    $('.ephemeral-box').addClass('hidden');
    $('#ephemeral-box-container').removeClass('ontop');
  }, 5000);
}


export function intersectionObserverLazyLoad(containerClass, margin, slowFunction) {
  if ("IntersectionObserver" in window) {
    var observedElements = document.querySelectorAll(`.${containerClass}`);
    console.debug("Setting up IntersectionObserver for", containerClass);
    var observerWorker = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          var container = entry.target;
          slowFunction(container);
          observerWorker.unobserve(container);
        }
      });
    }, { rootMargin: margin });

    observedElements.forEach((elt) => {
      observerWorker.observe(elt);
    });
  }
}

export function setupAjaxCursor() {
  $(document).ajaxStart(function() {
    $(document.body).css({'cursor' : 'wait'});
  }).ajaxStop(function() {
    $(document.body).css({'cursor' : 'default'});
  });
}
